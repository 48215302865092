/* eslint-disable */

import request from '@/utils/request.js'
import qs from 'qs'
// 登录接口
export const loginAPI = (params) => {
    // console.log(params)
    // 这里先用这个接口测试下，如果url以http开头会忽略baseURL，axios直接请求此地址
    return request({
        method: "POST",
        url: '/service/servicelogin.php',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, //请求头要加这个},
        data: params
    })
}
// 个人信息接口
export const msgAPI = (params) => {
    // console.log(params)
    // 这里先用这个接口测试下，如果url以http开头会忽略baseURL，axios直接请求此地址
    return request({
        method: "POST",
        url: '/service/serviceindex.php',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, //请求头要加这个},
        data: qs.stringify(params)
    })
}

// 资金明细接口
export const moneyAPI = (params) => {
    console.log(params)
    // 这里先用这个接口测试下，如果url以http开头会忽略baseURL，axios直接请求此地址
    // var newdate=new Date().getTime()
    return request({
        method: "POST",
        url: '/service/funddetails.php',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, //请求头要加这个},
        data:qs.stringify(params)
    })
}
// 验证码接口
export const codeAPI = (params) => {
    // 这里先用这个接口测试下，如果url以http开头会忽略baseURL，axios直接请求此地址
    return request({
        method: "POST",
        url: '/login/sendmsg.php?act=sendSMS',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, //请求头要加这个},
        data: {'mobile':params}
    })
}
// 收款人验证
export const payeeAPI = (params) => {
    // 这里先用这个接口测试下，如果url以http开头会忽略baseURL，axios直接请求此地址
    return request({
        method: "POST",
        url: '/service/serviceuserinfo.php',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, //请求头要加这个},
        data: {'membersn':params}
    })
}
// 手续费接口
export const HandlingAPI = (params) => {
    // 这里先用这个接口测试下，如果url以http开头会忽略baseURL，axios直接请求此地址
    return request({
        method: "Get",
        url: `/service/checktransfer.php`,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, //请求头要加这个},
        params
    })
}
// 转让提交
export const subAPI=(params) => {
    // 这里先用这个接口测试下，如果url以http开头会忽略baseURL，axios直接请求此地址
    return request({
        method: "POST",
        url: `/service/servicetransfer.php`,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, //请求头要加这个},
        data:params
    })
}
// 法大大链接页面获取
export const hrefAPI=(params) => {
    return request({
        method: "POST",
        url: `/service/MyAuthentication.php`,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, //请求头要加这个},
        data:params
    })
}

// 获取合同列表
export const contractAPI=(params) => {
    // 这里先用这个接口测试下，如果url以http开头会忽略baseURL，axios直接请求此地址
    return request({
        method: "POST",
        url: `/service/MyContract.php`,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, //请求头要加这个},
        data:params
    })
}

// 获取签署合同链接
export const nocontractAPI=(params) => {
    return request({
        method: "POST",
        url: `/service/MyContract.php`,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, //请求头要加这个},
        data:params
    })
}
// 兑换记录
export const recordAPI=(params) => {
    // console.log(params)
    return request({
        method: "POST",
        url: `/service/cashrecard.php`,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, //请求头要加这个},
        data:params
    })
}
// 银行卡信息
export const bankAPI=(params) => {
    // console.log(params)
    return request({
        method: "POST",
        url: `/service/bank.php`,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, //请求头要加这个},
        data:params
    })
}
// 结算提现
export const withAPI=(params) => {
    // console.log(params)
    return request({
        method: "POST",
        url: `/service/withdrawal.php`,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, //请求头要加这个},
        data:params
    })
}
// 签约管理
export const signingAPI=(params) => {
    // console.log(params)
    return request({
        method: "POST",
        url: `/service/ServicerBind.php`,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, //请求头要加这个},
        data:params
    })
}
// 我的签约
export const mysigningAPI=(params) => {
    // console.log(params)
    return request({
        method: "POST",
        url: `/service/MyBind.php`,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, //请求头要加这个},
        data:params
    })
}
// /service/ContractStatus.php //待签约合同查询 
export const contractStatusAPI=(params) => {
    // console.log(params)
    return request({
        method: "POST",
        url: `/service/ContractStatus.php`,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, //请求头要加这个},
        data:params
    })
}

// 客户培训确认
export const trainingAPI=(params) => {
    // console.log(params)
    return request({
        method: "POST",
        url: `service/training.php`,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, //请求头要加这个},
        data:params
    })
}
// 退出
export const quitAPI=(params) => {
    return request({
        method: "POST",
        url: `/service/servicelogin.php`,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, //请求头要加这个},
        data:params
    })
}