<template>
  <div class="home">
    <el-container>
      <el-header>
        <Mainheader></Mainheader>
      </el-header>
      <el-container>
        <el-aside>
          <Mainleft></Mainleft>
        </el-aside>
        <el-main>
          <div class="userinfo">
            <div class="userinfo1">
              <b
                style="
                  font-size: 20px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #000000;
                  margin-left:20px
                "
                >基础信息</b
              >
            </div>
            <div class="userinfo2">
              <ul>
                <li>公司名称</li>
                <li>服务办公地址</li>
                <li>姓名</li>
                <li>手机号</li>
              </ul>
              <ul>
                <li>
                 
                  <b>{{ userinfo.companyname }}</b>
                </li>
                <li>
                  <b>{{ userinfo.shop_area }}</b>
                </li>
                <li>
                  <b>{{ userinfo.realname }}</b>
                </li>
                <li>
                  <b>{{ userinfo.mobile }}</b>
                </li>
              </ul>
            </div>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
  <script>
import Mainleft from "@/components/left.vue";
import Mainheader from "@/components/header.vue";
import storage from "@/store/storage";
import { msgAPI } from "@/api/index";

export default {
  name: "userInfo",
  components: {
    Mainleft,
    Mainheader,
  },
  data() {
    return {
      userinfo: "",
    };
  },
  mounted() {
    var uid = JSON.parse(storage.get("uid"));
    if (!uid) {
      this.$router.push({ path: "/" });
    }
    msgAPI({ uid: uid }).then((res) => {
      // console.log(res)
      if (res.data.code == "200") {
        this.userinfo = res.data.userinfo;
      }
    });
  },
};
</script>

<style>
.home {
  background-image: url("../static/logo/index_bck.png");
  background-repeat: no-repeat;
}
.el-main{
    min-height: 700px
}
.userinfo1 {
  width: 1037px;
  height: 97px;
  
  /* background: linear-gradient(0deg, #d8e9fa, #fbfdfe); */
  background-image: url(../static/logo/recordbck.png);

  border-radius: 10px 10px 0px 0px;
  text-align: left;
  line-height: 96px;
}
.userinfo1 b {
  margin-left: 10px;
}
.userinfo2 {
  width: 1037px;
  height: 244px;
  background: #ffffff;
  box-shadow: 0px 4px 3px 0px rgba(237, 248, 255, 0.75);
  border-radius: 10px;
  display: flex;
  text-align: left;
}
.userinfo li {
  list-style: none;
  line-height: 52px;
}
.userinfo2 > ul > li {
  height: 52px;
}
</style>