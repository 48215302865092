<template>
  <div class="home">
    <el-container>
      <el-header>
        <mainheader></mainheader>
      </el-header>
      <el-container>
        <el-aside>
          <Mainleft></Mainleft>
        </el-aside>
        <el-main>
          <div>
            <div class="main-center">
              
                <img v-show="isShow=='true'"
                src="../static/logo/main-center.png"
                alt=""
                style="display: block;"
               />
               <img v-show="isShow!='true'"
                src="../static/logo/main-centerfw.png"
                alt=""
                style="display: block;"
               
               />
          

              
              <div class="main-centerdiv">
                <div style="display: flex">
                  <ul>
                    <li>
                      <p>公司名称</p>
                    </li>
                    <li v-show="isShow=='true'">
                      <p>服务商ID</p>
                    </li>
                    <li v-show="isShow!='true'">
                      <p>代理商ID</p>
                    </li>
                    <li v-show="isShow=='true'">
                      <p>服务商类型</p>
                    </li>
                    <li v-show="isShow!='true'">
                      <p>代理商类型</p>
                    </li>
                    <li>
                      <p>姓名</p>
                    </li>
                    <li>
                      <p>手机号</p>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <p>{{ datainfo.companyname }}</p>
                    </li>
                    <li>
                      <p>{{ datainfo.membersn }}</p>
                    </li>
                    <li v-show="isShow=='true'">
                      <p>{{ datainfo.levelname }}</p>
                    </li>
                    <li v-show="isShow!='true'">
                      <p>{{ datainfo.agentname }}({{ datainfo.agent_area }})</p>
                    </li>
                    <li>
                      <p>{{ datainfo.realname }}</p>
                    </li>
                    <li>
                      <p>{{ datainfo.mobile }}</p>
                    </li>
                  </ul>
                  <ul>
                    <li
                      v-if="datainfo.agentlevelid == ''"
                      style="display: none"
                    >
                      <p>董事类型</p>
                    </li>
                    <li
                      v-else-if="datainfo.agentlevelid == '11'"
                      style="display: block"
                    >
                      <p>董事类型</p>
                    </li>
                    <li
                      v-else-if="datainfo.agentlevelid == '13'"
                      style="display: block"
                    >
                      <p>董事类型</p>
                    </li>
                    <li v-else style="display: none">
                      <p>董事类型</p>
                    </li>
                  </ul>

                  <ul>
                    <li>
                      <p v-if="datainfo.agentlevelid == 11">
                        {{ datainfo.agentname }}
                      </p>
                      <p v-else-if="datainfo.agentlevelid == 13">
                        {{ datainfo.agentname }}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <ul class="main-center-bottom" v-show="isShow=='true'">
                <li>
                  <div>
                    <div
                      style="display: flex; margin-left: 36%; margin-top: 20%"
                    >
                      <img src="../static/logo/yidou.png" alt="" />
                      <div>易豆余额</div>
                    </div>
                    <p>{{ datainfo.servicepoint }}</p>
                  </div>
                </li>
                <li>
                  <div>
                    <div style="display: flex; margin-left: 36%; margin-top: 20%">
                      <img src="../static/logo/yiquan.png" alt="" />
                    <div>易券余额</div>
                    </div>
                    <p>{{ datainfo.coupon }}</p>
                  </div>
                </li>
                <li>
                  <div>
                    <div style="display: flex; margin-left: 36%; margin-top: 20%">
                      <img src="../static/logo/heyingquan.png" alt="" />
                    <div>合赢券余额</div>
                    </div>
                    <p>{{ datainfo.wincoupon }}</p>
                  </div>
                </li>
              </ul>
              <ul class="main-center-bottom" v-show="isShow!='true'">
                <li style="background-color: #FEF8E4;">
                  <div>
                    <div
                      style="display: flex; margin-left: 36%; margin-top: 20%"
                    >
                      <img src="../static/logo/yidou.png" alt="" />
                      <div>易豆余额</div>
                    </div>
                    
                    <p>{{ datainfo.agentpoint }}</p>

                  </div>
                </li>
                <li style="background-color: #FBEEEB;">
                  <div>
                    <div style="display: flex; margin-left: 36%; margin-top: 20%">
                      <img src="../static/logo/yiquan.png" alt="" />
                    <div>易券余额</div>
                    </div>
                    <p>{{ datainfo.coupon }}</p>
                  </div>
                </li>
                <li style="background-color: #EEECFE;">
                  <div>
                    <div style="display: flex; margin-left: 36%; margin-top: 20%">
                      <img src="../static/logo/heyingquan.png" alt="" />
                    <div>合赢券余额</div>
                    </div>
                    <p>{{ datainfo.wincoupon }}</p>
                  </div>
                </li>
              </ul>
            </div>
            <div class="mask" v-if="showmask">
                <div class="alertcenter">
                   <img src="../static/logo/chenggongguanbi.png" alt="" style="position: absolute;right: 20px;top: 20px;z-index: 10000;pointer-events: auto;" @click="close">
                   <button @click="qianshu" style="z-index: 10000;pointer-events: auto;">马上去签署</button>
                </div>
            </div>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
/* eslint-disable */ 
import Mainleft from "@/components/left.vue";
import Mainheader from "@/components/header.vue";
import storage from "@/store/storage";
import { msgAPI,contractStatusAPI } from "@/api/index";
import * as Base64 from 'js-base64';


// import axios from 'axios'
export default {
  name: "HomeView",
  data() {
    return {
      datainfo: Object,
      isShow:'',
      flag:'',
      showmask:false
    };
  },
  components: {
    Mainleft,
    Mainheader,
  },
  methods: {
    qianshu(){
      this.$router.push(
          {
            name: "settLement",
            path: `/settLement`,
            query: {
              index: 8,
              flag: this.flag,
            },
          },
          () => {}
        );
    },
    close(){
      this.showmask=!this.showmask
    }
  },
  mounted() {
    var uid = JSON.parse(storage.get("uid"));
    this.flag = JSON.parse(storage.get("flag"));
    if (!uid) {
      this.$router.push({ path: "/" });
    };
  
    // debugger
    if(this.flag=='1'){
      // alert(1111111111111)
      this.isShow='true'
      this.flag='1'
    }else if(this.flag=='2'){
      // alert(222222222222)
      this.flag='2'
      this.isShow='false'
    }else{
      this.$router.push({
        path:'/home',
      })
    }
    msgAPI({ uid: uid,type:this.flag }).then((res) => {
      if (res.data.code == "200") {
        this.datainfo = res.data.userinfo;
        localStorage.setItem('userinfo',JSON.stringify(res.data.userinfo))
      }
    });
    contractStatusAPI({uid:uid,type:this.flag}).then((res)=>{
      if(res.data.code=='1'){
        if(res.data.data.count>'0'){
          alert('尊敬的服务商您好，您有一份待签合同，确保后续结算顺畅');
          this.$router.push({
            path:'/contract',
            query: {
              index: 4,
            },
          })
        }
      }else{
        alert(res.data.msg)
      }
    })
  },
  computed: {},
  // watch: {
  //   // 监听路由对象，一旦路径变化，函数将被调用
  //   '$route': {
  //     handler: function (to, from) {
  //       console.log(to.query.flag,from.query.flag)
  //       // 检查路径是否有所改变
  //       if (from.query.flag !== to.query.flag) {
  //         // 如果需要重定向到其他页面

  //       }
  //     },
  //     // 设置为深度监听
  //     deep: true
  //   }
  // }
};
</script>
<style>
.home {
  background-image: url("../static/logo/index_bck.png");
}
.el-main{
    min-height: 700px
}
.main-center {
  margin: auto;
}
.main-centerdiv {
  /* display: flex; */
  margin-top: -16px;
  width: 1037px;
  height: 246px;
  background: linear-gradient(to bottom, #d8e9fa, #fbfdfe);
  box-shadow: 0px 4px 3px 0px rgba(237, 248, 255, 0.75);
  border-radius: 10px;
  text-align: left;
}
.main-centerdiv ul:nth-child(1) li {
  /* margin-top:19px ; */
  height: 24.5px;
}

.main-centerdiv ul:nth-child(2) li {
  line-height: 20px;
  height: 25px;
  /* opacity: 0; */
}
.main-centerdiv ul:nth-child(3) {
  margin-top: 94px;
  margin-left: 30px;
}
.main-centerdiv ul:nth-child(4) {
  margin-top: 94px;
}
.main-centerdiv ul li {
  list-style: none;
}
.main-centerdiv b {
  margin-left: 45px;
}

.main-center-bottom {
  width: 1037px;
  margin-left: -40px;
  /* display: block; */
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.main-center-bottom li {
  list-style: none;
  width: 320px;
  height: 199px;
  background: #e6f4fd;
  border-radius: 10px;
}
.main-center-bottom li div {
  /* margin-top: 25%; */
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #212533;
}
.main-center-bottom li div b {
  color: #16253e;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 500;
}
.info li {
  height: 22px;
}
.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  pointer-events: none;
  overflow: hidden;
}
.alertcenter{
  position: relative;
  top: 35%;
  left: 35%;
  width: 483px;
  height: 300px;
  background-image: url(../static/logo/thirddaiqianhetong.png);
}
.alertcenter>button{
  text-align: center;
  position: absolute;bottom: 40px;left:180px;width: 146px;
height: 42px;
border-radius: 21px;
border: 0px;
font-family: Source Han Sans CN;
font-weight: 400;
font-size: 17px;
color: #FFFFFF;
letter-spacing: 1px;
background: #30D363;
border-radius: 21px;
}
</style>
