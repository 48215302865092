const storage = {

    set(key, value) {
        // console.log(key,value)
        window.localStorage.setItem(key, value)
    },
    
    get(key,value) {
        // console.log(key,value)
        return window.localStorage.getItem(key,value)
    },

    remove(key) {
        window.localStorage.removeItem(key)
    }

    
}

export default storage
